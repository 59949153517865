import { FC } from 'react';
import { memo } from '../../../util/memo';
import {
  SelectionRouted,
  SelectionRoutedProps,
} from '../../routing/SelectionRouted';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { FullTournamentFetched } from '../../tournaments/FullTournamentFetched';
import { isTournamentPath } from '../../../../functions/src/util/tournaments/isTournamentPath';

export type SelectionEventProps = Omit<
  SelectionRoutedProps,
  'state' | 'RenderContent'
>;

const SelectionEventUnmemoized: FC<SelectionEventProps> = (props) => {
  const eventSelectedState = useRouterState({ key: 'event' });

  return (
    <SelectionRouted
      state={eventSelectedState}
      validateState={isTournamentPath}
      RenderContent={FullTournamentFetched}
      {...props}
    />
  );
};

export const SelectionEvent = memo(SelectionEventUnmemoized);
