import { LoadingButton } from '../../buttons/LoadingButton';
import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useRouter } from '../../../hooks/routing/useRouter';
import { useErrorAlert } from '../../../hooks/useErrorAlert';
import { ORGANIZER_ERROR_LIBRARY } from '../../../../functions/src/util/error/library/organizer';

export const TOURNAMENT_PUBLISHING_ID = 'TOURNAMENT_PUBLISHING_DIALOG';

export const PublishTournamentButton = memo(
  function PublishTournamentUnmemoized() {
    const [eventDocPath] = useRouterState({ key: 'event' });
    const { push } = useRouter();
    const { catchError } = useErrorAlert(ORGANIZER_ERROR_LIBRARY);

    const publish = useCallback(async () => {
      if (!eventDocPath) {
        return;
      }
      const { publishTournament } = await import(
        '../../../firebaseCloud/tournament/organizer/publishTournament'
      );
      const pathSegments = eventDocPath.split('/');
      const tournamentId = pathSegments[pathSegments.length - 1];
      const gameId = pathSegments[pathSegments.length - 3];
      await publishTournament({
        tournamentId,
      });
      const docPath = `Game/${gameId}/Tournament/${tournamentId}`;
      await push(`/?event=${docPath}&edit-mode=primary`);
      window.location.reload();
    }, [eventDocPath, push]);

    const publishSafe = useCallback(async () => {
      await catchError(publish);
    }, [catchError, publish]);

    return (
      <Stack spacing={2}>
        <Tooltip
          arrow
          title="Once published, you will no longer be able to edit certain fields and your event will be displayed to all users."
          placement="bottom"
        >
          <div>
            <LoadingButton
              variant="contained"
              color="success"
              size="large"
              sx={{ borderRadius: '8px', minWidth: '150px', height: '42px' }}
              onClick={publishSafe}
            >
              Publish
            </LoadingButton>
          </div>
        </Tooltip>
      </Stack>
    );
  },
);
