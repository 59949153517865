import { FC, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { EventFlowBase } from './EventFlowBase';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { Box } from '@mui/system';
import { AlgoliaEventsCalendar } from '../../algolia/AlgoliaEventsCalendar';
import { Selectable } from '../../Selectable';
import {
  EventHit,
  RenderWrapper,
} from '../../algolia/catalog-wrappers/EventsCalendar';
import { NewTabTournamentCard } from '../../cards/tournament/NewTabTournamentCard';
import { useCreateTournament } from '../../../hooks/organizer/useCreateTournament';
import { EVENT_PUBLISHED } from '../../../../functions/src/util/algolia/config/events';

export const CopyEventCalendar: FC = memo(function CopyEventUnmemoized() {
  const {
    eventCreationSettings: { eventType },
    event,
    setEvent,
  } = useCreateEventContext();
  const { createTournament } = useCreateTournament();
  const { id: tournamentId, title, gameTitle, gameId, date } = event || {};
  const someEventSelected = useMemo(() => {
    return !!tournamentId;
  }, [tournamentId]);

  const buttonMessage = useMemo(() => {
    if (!someEventSelected) {
      return 'Copy Tournament';
    }
    return `Copy Tournament: ${title} (${gameTitle})`;
  }, [title, someEventSelected, gameTitle]);

  const SelectableWrapper = useCallback<RenderWrapper<EventHit<Date>, Date>>(
    ({ hit, children }) => {
      const { id } = hit;
      return (
        <Selectable
          value={id}
          isSelected={id === tournamentId}
          onChange={(_, isSelected) => {
            if (isSelected) {
              setEvent(hit);
            }
          }}
          borderRadius="10px"
        >
          {children}
        </Selectable>
      );
    },
    [setEvent, tournamentId],
  );

  const copySelectedTournament = useCallback(async () => {
    if (!gameId || !tournamentId) {
      return;
    }
    await createTournament(gameId, tournamentId);
  }, [createTournament, gameId, tournamentId]);

  const configureOptions = useMemo(() => {
    return {
      filters: EVENT_PUBLISHED,
    };
  }, []);

  return (
    <EventFlowBase
      title={`Copy ${eventType}`}
      subtitle={`Select a ${eventType.toLowerCase()} to copy.`}
      Content={
        <Box sx={{ width: '100%' }}>
          <AlgoliaEventsCalendar
            Wrapper={SelectableWrapper}
            Card={NewTabTournamentCard}
            configureOptions={configureOptions}
            Title={'Events Calendar'}
            height="520px"
            initialDate={date}
          />
        </Box>
      }
      continueButtonProps={{
        isAsync: true,
        children: buttonMessage,
        onClick: copySelectedTournament,
        disabled: !someEventSelected,
      }}
    />
  );
});
