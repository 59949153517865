import { useCallback, useMemo } from 'react';
import { TournamentPhase } from '../../../functions/src/types/firestore/Game/Tournament';
import { useRouterState } from '../routing/useRouterState';

export const useIsEditingTournament = (phase?: TournamentPhase) => {
  const state = useRouterState({
    key: 'edit-mode',
    defaultValue: 'primary',
  });

  const [mode, setMode] = state;

  const isEditing = useMemo(() => {
    if (phase === 'unpublished') {
      return mode === 'primary';
    }
    if (phase === 'registration') {
      return mode === 'secondary';
    }
    return false;
  }, [mode, phase]);

  const toggleMode = useCallback(() => {
    setMode((prevMode) => {
      return prevMode === 'primary' ? 'secondary' : 'primary';
    });
  }, [setMode]);

  return { isEditing, toggleMode, state };
};
