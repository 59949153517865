import { useCallback, useMemo } from 'react';
import { useEditable } from '../../contexts/organizer/EditableStepsContext';
import { useRouter } from '../routing/useRouter';
import { useWizard } from '../../components/wizard/Wizard';
import { getGroupNumber } from '../../util/realtime/getGroupNumber';
import { useGroupBasic } from '../group/useGroupBasic';

export const useCreateTournament = () => {
  const { setDocumentPath: setEditableDocumentPath } = useEditable();
  const { go } = useWizard();
  const { push } = useRouter();

  const { groupId, groupType } = useGroupBasic();

  const groupNumberPromise = useMemo(() => {
    if (groupId) {
      return getGroupNumber(groupId) as Promise<number>;
    }
    return Promise.resolve(null);
  }, [groupId]);

  const closeDialog = useCallback(() => {
    go(undefined);
  }, [go]);
  const createTournament = useCallback(
    async (gameId: string, previousId?: string) => {
      const { createTemplateTournament } = await import(
        '../../firebaseCloud/tournament/organizer/createTemplateTournament'
      );
      const groupNumber = (await groupNumberPromise) as number;
      const groupNumberString = groupNumber?.toString();

      const { tournamentNew } = await createTemplateTournament({
        gameId,
        previousId,
        groupNumberString,
      });
      const { id: newEventId, gameId: newGameId } = tournamentNew;
      const documentPath = `Game/${newGameId}/Tournament/${newEventId}`;

      setEditableDocumentPath(documentPath);
      const groupPath =
        groupId && groupType ? `/${groupType.toLowerCase()}/${groupId}` : '';
      push(`${groupPath}/?event=${documentPath}&edit-mode=primary`);
      closeDialog();
    },
    [
      closeDialog,
      groupId,
      groupNumberPromise,
      groupType,
      push,
      setEditableDocumentPath,
    ],
  );

  return { createTournament };
};
