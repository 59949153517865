import { FC, useMemo } from 'react';
import { Stack } from '@mui/system';
import { useAuth } from '../../contexts/AuthContext';
import { EditorPanel } from '../organizer/event-editing/EditorPanel';
import { TournamentDetails, TournamentDetailsProps } from './TournamentDetails';
import { useIsEditingTournament } from '../../hooks/organizer/useIsEditingTournament';
import dynamic from 'next/dynamic';
import {
  TournamentPhaseEditable,
  TOURNAMENT_PHASE_EDITABLE,
} from 'functions/src/types/firestore/Game/Tournament';
import { memo } from '../../util/memo';
const TournamentDetailsEditing = dynamic(
  () => {
    return import('./TournamentOverviewEditing');
  },
  { ssr: false },
);

// TODO: Rename after refactoring TournamentDetailsEditing to combine with
// TournamentDetails
const FullTournamentDetailsUnmemoized: FC<TournamentDetailsProps> = ({
  tournament,
  tournamentPath,
  heats,
  heatsPath,
  bracket,
  bracketPath,
}) => {
  const { phase } = tournament;
  const { isEditing } = useIsEditingTournament(phase);

  const { uid } = useAuth();
  const hasEditingPermissions = useMemo(() => {
    return (
      !!uid &&
      (tournament.userIdsContributors || []).includes(uid) &&
      TOURNAMENT_PHASE_EDITABLE.includes(
        tournament.phase as TournamentPhaseEditable,
      )
    );
  }, [uid, tournament]);

  const details = useMemo(() => {
    if (isEditing && hasEditingPermissions) {
      return <TournamentDetailsEditing tournament={tournament} />;
    }
    return (
      <TournamentDetails
        tournament={tournament}
        tournamentPath={tournamentPath}
        heats={heats}
        heatsPath={heatsPath}
        bracket={bracket}
        bracketPath={bracketPath}
      />
    );
  }, [
    bracket,
    bracketPath,
    hasEditingPermissions,
    heats,
    heatsPath,
    isEditing,
    tournament,
    tournamentPath,
  ]);

  return (
    <Stack spacing={4}>
      {hasEditingPermissions && <EditorPanel phase={phase} />}
      {details}
    </Stack>
  );
};

export const FullTournamentDetails = memo(FullTournamentDetailsUnmemoized);
