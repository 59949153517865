import {
  CREATION_METHODS,
  EVENT_TYPES,
  EventCreationSettings,
  DayOfWeek,
} from './constants';

export type EventCreationSettingsAction =
  | { type: 'SET_METHOD'; payload: typeof CREATION_METHODS[number] | 'Edit' }
  | { type: 'SET_EVENT_TYPE'; payload: typeof EVENT_TYPES[number] }
  | { type: 'SET_IS_RECURRING'; payload: boolean }
  | { type: 'SET_RECURRENCE'; payload: EventCreationSettings['recurrence'] }
  | { type: 'SET_REPETITION_FREQUENCY'; payload: number }
  | { type: 'SET_REPETITION_UNITS'; payload: 'weeks' | 'months' }
  | { type: 'SET_REPETITION_COUNT'; payload: number }
  | { type: 'SET_REPETITION_DAY_OF_WEEK'; payload: DayOfWeek };

const actionHandlers: Record<
  string,
  (state: EventCreationSettings, payload: any) => EventCreationSettings
> = {
  SET_METHOD: (state, payload: typeof CREATION_METHODS[number] | 'Edit') => {
    return { ...state, method: payload };
  },
  SET_EVENT_TYPE: (state, payload: typeof EVENT_TYPES[number]) => {
    return { ...state, eventType: payload };
  },
  SET_IS_RECURRING: (state, payload: boolean) => {
    return { ...state, isRecurring: payload };
  },
  SET_REPETITION_FREQUENCY: (state, payload: number) => {
    return {
      ...state,
      recurrence: {
        ...state.recurrence,
        repeatEvery: { ...state.recurrence.repeatEvery, frequency: payload },
      },
    };
  },
  SET_REPETITION_UNITS: (state, payload: 'weeks' | 'months') => {
    return {
      ...state,
      recurrence: {
        ...state.recurrence,
        repeatEvery: { ...state.recurrence.repeatEvery, timePeriod: payload },
      },
    };
  },
  SET_REPETITION_COUNT: (state, payload: number) => {
    return {
      ...state,
      recurrence: { ...state.recurrence, repeatTimes: payload },
    };
  },
  SET_REPETITION_DAY_OF_WEEK: (state, payload: DayOfWeek) => {
    return {
      ...state,
      recurrence: { ...state.recurrence, repeatDay: payload },
    };
  },
};

export function eventCreationSettingsReducer(
  state: EventCreationSettings,
  action: EventCreationSettingsAction,
): EventCreationSettings {
  const { type, payload } = action;
  // eslint-disable-next-line security/detect-object-injection
  const handler = actionHandlers[type];
  if (!handler) {
    console.warn(`Action not ${type} implemented`);
    return state;
  }
  return handler(state, payload);
}
