import { memo } from '../../util/memo';
import { Typography } from '@mui/material';
import { SelectableOption } from '../SelectableOption';

export type SelectableMethodOptionProps = {
  onSelect: () => void;
  value: string;
  isSelected: boolean;
};

export const SelectableMethodOption = memo(
  function SelectableMethodOptionUnmemoized({
    onSelect,
    value,
    isSelected,
  }: SelectableMethodOptionProps) {
    return (
      <SelectableOption onClick={onSelect} isSelected={isSelected}>
        <Typography variant="subtitle1" color="text.primary">
          {value}
        </Typography>
      </SelectableOption>
    );
  },
);
