import { FC, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { EventFlowBase } from './EventFlowBase';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import { GameDropdownSearch } from '../../algolia/catalog-wrappers/GameDropdownSearch';
import { Game } from '../../../../functions/src/types/firestore/Game';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { useCreateTournament } from '../../../hooks/organizer/useCreateTournament';
import { GAME_FILTERS } from '../../../../functions/src/util/algolia/config/game';

export const GAME_HITS = 50;
export const GAME_SELECT_TITLE = 'Select Game';

export const SelectGame: FC = memo(function SelectGameUnmemoized() {
  const {
    eventCreationSettings: { eventType },
  } = useCreateEventContext();
  const [game, setGame] = useState<Game<Date> | undefined>();
  const { createTournament } = useCreateTournament();
  const { id } = game || {};
  const subtitle = useMemo(() => {
    return `Select the game that you want to create the ${eventType.toLowerCase()} for.`;
  }, [eventType]);

  const selectGame = useCallback(async () => {
    if (!id) {
      return;
    }
    await createTournament(id);
  }, [createTournament, id]);

  const catalogWrapperFrom = useCallback(
    (props) => {
      return <GameDropdownSearch {...props} onGameSelect={setGame} />;
    },
    [setGame],
  );

  return (
    <EventFlowBase
      title={GAME_SELECT_TITLE}
      subtitle={subtitle}
      continueButtonProps={{
        isAsync: true,
        children: 'Create Tournament',
        onClick: selectGame,
        disabled: !game,
      }}
      Content={
        <AlgoliaLayout
          CatalogWrapper={catalogWrapperFrom}
          configureOptions={{
            filters: GAME_FILTERS,
            hitsPerPage: GAME_HITS,
          }}
          index="CONTENT"
        />
      }
    />
  );
});
