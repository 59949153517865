import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { useCallback, useState } from 'react';
import { memo } from '../../../util/memo';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useCreateEventWizard } from '../../../hooks/organizer/useCreateEventWizard';

export const DELETE_SUCCESS_ID = 'DELETE_SUCCESS_ID';
export const DeleteTournamentContent = memo(
  function DeleteTournamentContentUnmemoized() {
    const [eventDocPath, setEventDocPath] = useRouterState({ key: 'event' });
    const { open: openDeleteSuccessDialog } = useCreateEventWizard(
      'Delete Tournament Success',
    );

    const [error, setError] = useState<boolean>(false);

    const deleteTournament = useCallback(async () => {
      if (!eventDocPath) {
        return;
      }
      const { deleteTournament: deleteTournamentFunc } = await import(
        '../../../firebaseCloud/tournament/organizer/deleteTournament'
      );
      const pathSegments = eventDocPath.split('/');
      try {
        setError(false);
        setEventDocPath('');
        await deleteTournamentFunc({
          tournamentId: pathSegments[pathSegments.length - 1],
        });
        openDeleteSuccessDialog();
      } catch (e) {
        setError(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDocPath, setEventDocPath]);
    return (
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        gap={4}
        sx={{ textAlign: 'center' }}
      >
        <DialogActionsStandard
          buttons={[
            {
              isAsync: true,
              color: 'error',
              variant: 'contained',
              onClick: deleteTournament,
              children: 'Delete',
            },
          ]}
        />

        {error && (
          <Typography color="error" textTransform="none" mb={2}>
            {`An error has occurred whilst deleting the tournament. Please contact BluMint support if the error persists.`}
          </Typography>
        )}
      </Stack>
    );
  },
);
