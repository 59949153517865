import { useDialog } from '../useDialog';
import { useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { LivestreamConfiguration } from '../../components/livestream/mux/LivestreamConfiguration';
import { DialogActionsLivestream } from '../../components/livestream/mux/DialogActionsLivestream';

export const LIVESTREAM_CONFIGURATION_DIALOG_ID =
  'LIVESTREAM_CONFIGURATION_DIALOG';

export type UseLivestreamConfigurationDialogParams = {
  channelId: string;
  onClose?: () => void;
};

export const useLivestreamConfigurationDialog = ({
  channelId,
  onClose,
}: UseLivestreamConfigurationDialogParams) => {
  const { userData } = useAuth();
  const { open: openDialog, close: closeDialog } = useDialog(
    LIVESTREAM_CONFIGURATION_DIALOG_ID,
  );

  const close = useCallback(() => {
    closeDialog();
    onClose?.();
  }, [closeDialog, onClose]);

  const open = useCallback(async () => {
    const { setLivestreamFor } = await import(
      '../../firebaseCloud/mux/setLivestreamFor'
    );

    openDialog({
      title: 'Livestream Settings',
      description: <LivestreamConfiguration />,
      showCloseIcon: true,
      children: <DialogActionsLivestream onCancel={close} />,
    });

    if (userData?.hidden.livestreamFor !== channelId && userData?.id) {
      await setLivestreamFor({
        livestreamFor: channelId,
      });
    }
  }, [
    openDialog,
    close,
    userData?.id,
    userData?.hidden.livestreamFor,
    channelId,
  ]);
  return { open, close };
};
