import { memo } from '../../util/memo';
import {
  GradientIconButton,
  GradientIconButtonProps,
} from '../gradients/GradientIconButton';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

export type EditButtonProps = Omit<GradientIconButtonProps, 'IconComponent'> & {
  onEditChange: () => void;
  isEditing: boolean;
  disable?: boolean;
};

const EditButtonUnmemoized = ({
  onEditChange,
  isEditing,
  disable,
  ...props
}: EditButtonProps) => {
  return (
    <GradientIconButton
      gradientColor={disable ? 'disabled.vertical' : 'primary.horizontal'}
      aria-label="icon to edit profile panel"
      size="medium"
      onClick={() => {
        return onEditChange();
      }}
      disabled={!!disable}
      IconComponent={isEditing ? CheckCircleRoundedIcon : EditRoundedIcon}
      {...props}
    />
  );
};

export const EditButton = memo(EditButtonUnmemoized);
