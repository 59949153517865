// type EditableFields<TDocument extends EventDocument> = (keyof TDocument)[];

export const EVENT_TYPE_TO_EDITABLE_FIELDS = {
  Tournament: [
    'title',
    'date',
    'endDate',
    'checkInTime',
    'readyTime',
    'imgUrl',
    'sponsors',
    'maxTeamSize',
    'minTeamSize',
    'maxTeamCount',
    'teamSizeRange',
    'region',
    'bracketType',
    'tournamentDetails',
    'tournamentThumbnailImgUrl',
    'payouts',
    'prizePoolId',
    'registrationOptions',
    'skipCheckIn',
    'squareImgUrl',
    'roles',
    'checkInDuration',
    'visibility',
    'continuousRegistration',
  ],
} as const;

export const EVENT_TYPES = ['Tournament', 'Giveaway'];

export const CREATION_METHODS = ['New', 'Copy'];

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export type DayOfWeek = typeof DAYS_OF_WEEK[number];

export type EventCreationSettings = {
  method: typeof CREATION_METHODS[number] | 'Edit';
  eventType: typeof EVENT_TYPES[number];
  isRecurring: boolean;
  recurrence: {
    repeatEvery: {
      frequency: number;
      timePeriod: 'weeks' | 'months';
    };
    repeatDay: DayOfWeek;
    repeatTimes: number;
  };
};

export const EVENT_CREATION_SETTINGS_DEFAULT = {
  eventType: 'Tournament',
  method: 'Copy',
  isRecurring: false,
  recurrence: {
    repeatEvery: {
      frequency: 1,
      timePeriod: 'weeks' as 'weeks' | 'months',
    },
    repeatDay: 'monday' as DayOfWeek,
    repeatTimes: 1,
  },
};
