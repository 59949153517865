import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { useCountViewers } from '../../../hooks/mux/useCountViewers';
import { GradientTypography } from '../../gradients/GradientTypography';
import { GradientButton } from '../../gradients/GradientButton';
import { useMemo } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useLivestream } from '../../../contexts/LivestreamContext';

export type ViewerCountButtonProps = {
  onClick: () => void;
};

const ViewerCountButtonUnmemoized = ({ onClick }: ViewerCountButtonProps) => {
  const theme = useTheme();
  const { isLive } = useLivestream();
  const { viewerCount } = useCountViewers();

  const viewerCountButton = useMemo(() => {
    return (
      !!viewerCount &&
      isLive && (
        <GradientButton
          variant="contained"
          startIcon={VisibilityOutlinedIcon}
          onClick={onClick}
          sx={{
            background: theme.palette.background.elevation[12],
            whiteSpace: 'nowrap',
            p: 0,
            '&:hover': {
              background: `${theme.palette.background.elevation[18]}`,
            },
          }}
        >
          <GradientTypography
            sx={{ whiteSpace: 'nowrap' }}
          >{`${viewerCount} watching`}</GradientTypography>
        </GradientButton>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClick, viewerCount, isLive]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{viewerCountButton}</>;
};

export const ViewerCountButton = memo(ViewerCountButtonUnmemoized);
