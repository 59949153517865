import { useMemo, FC, useRef, useCallback } from 'react';
import { memo } from '../../util/memo';
import Box from '@mui/material/Box';
import { DEFAULT_SEGMENT_NAME } from './SelectableRouted';
import { RouterStateGetter } from '../../hooks/routing/useRouterState';
import { useRouterStateFallback } from '../../hooks/routing/useRouterStateFallback';
import { useScrollTo } from '../../hooks/useScrollTo';

export type SelectionContentProps = {
  value: string;
  onReady: () => void;
};

export type SelectionRoutedProps = {
  RenderContent: FC<SelectionContentProps>;
  state?: RouterStateGetter;
  validateState?: (value: string) => boolean;
  autoScroll?: boolean;
  minHeight?: string;
};

function SelectionRoutedUnmemoized({
  RenderContent,
  state,
  validateState,
  autoScroll = true,
  minHeight = '100dvh',
}: SelectionRoutedProps) {
  const [selectedValue] = useRouterStateFallback({
    defaultKey: DEFAULT_SEGMENT_NAME,
    state,
  });

  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollToRef } = useScrollTo();
  const scrollToContent = useCallback(() => {
    if (autoScroll && scrollRef.current && hasContent.current) {
      scrollToRef(scrollRef);
    }
  }, [autoScroll, scrollToRef]);

  const hasContent = useRef<boolean>(false);
  const content = useMemo(() => {
    if (!selectedValue || (validateState && !validateState(selectedValue))) {
      hasContent.current = false;
      return undefined;
    }
    hasContent.current = true;
    return (
      <Box sx={{ minHeight, width: '100%' }}>
        <RenderContent value={selectedValue} onReady={scrollToContent} />
      </Box>
    );
  }, [selectedValue, validateState, minHeight, RenderContent, scrollToContent]);

  return (
    <Box sx={{ width: '100%' }} ref={scrollRef}>
      {content}
    </Box>
  );
}

export const SelectionRouted = memo(SelectionRoutedUnmemoized);
