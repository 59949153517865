import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useDialog } from '../../../hooks/useDialog';
import { DeleteTournamentContent } from './DeleteTournamentContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const DeleteTournamentButton = memo(
  function DeleteTournamentUnmemoized() {
    const { open: openDialog } = useDialog('DELETE_TOURNAMENT_DIALOG');
    const openDeleteDialog = useCallback(() => {
      openDialog({
        title: 'Delete Tournament',
        description: (
          <Typography variant="h6">
            Are you sure you want to delete this tournament? This action is
            irreversible, you will be unable to retrieve the same tournament
            again.
          </Typography>
        ),
        children: <DeleteTournamentContent />,
      });
    }, [openDialog]);

    return (
      <Button
        color="error"
        variant="contained"
        size="large"
        sx={{ borderRadius: '8px', minWidth: '150px' }}
        onClick={() => {
          return openDeleteDialog();
        }}
      >
        Delete
      </Button>
    );
  },
);
