import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';

export type VideoPlaceholderProps = {
  placeholder?: ReactNode;
};

const VideoPlaceholderUnmemoized: FC<VideoPlaceholderProps> = ({
  placeholder = 'Livestream Disconnected',
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: '56.25%', // 16:9 aspect ratio
        position: 'relative',
        backgroundColor: theme.palette.background.elevation[6],
      }}
    >
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        {placeholder}
      </Typography>
    </Box>
  );
};

export const VideoPlaceholder = memo(VideoPlaceholderUnmemoized);
