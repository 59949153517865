import Box from '@mui/material/Box';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { ReactElement } from 'react';
import { memo } from '../../util/memo';

export type ComingSoonTooltipProps = TooltipProps & {
  title: string;
  children: ReactElement;
};

export const ComingSoonTooltip = memo(function ComingSoonTooltipUnmemoized({
  title,
  children,
  ...props
}: ComingSoonTooltipProps) {
  const { sx: tooltipSx, ...restProps } = props;
  return (
    <Tooltip
      arrow
      title={`${title} coming soon!`}
      sx={{ width: '100%', ...tooltipSx }}
      {...restProps}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
});
