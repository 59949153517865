import { memo } from '../../../util/memo';
import { useActiveTournamentChannel } from '../../../hooks/messaging/useActiveTournamentChannel';
import { ViewerCountButton, ViewerCountButtonProps } from './ViewerCountButton';

export type TournamentViewStreamButtonProps = Omit<
  ViewerCountButtonProps,
  'onClick'
> & {
  tournamentId: string;
};

const TournamentViewStreamButtonUnmemoized = ({
  tournamentId,
  ...props
}: TournamentViewStreamButtonProps) => {
  const { openGeneralChannel } = useActiveTournamentChannel(tournamentId);
  return <ViewerCountButton onClick={openGeneralChannel} {...props} />;
};

export const TournamentViewStreamButton = memo(
  TournamentViewStreamButtonUnmemoized,
);
