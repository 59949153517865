import { Avatar, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DAYS_OF_WEEK,
  DayOfWeek,
} from 'src/contexts/organizer/create-event/constants';
import { FC } from 'react';
import { memo } from '../../../../util/memo';
import { GradientTypography } from '../../../gradients/GradientTypography';

export type DaySelectProps = {
  daySelected: DayOfWeek;
  onDaySelect: (day: DayOfWeek) => void;
};

// TODO: is AvatarSelect warranted? Could we use this in
// other places?
export const DaySelect: FC<DaySelectProps> = memo(
  function DaySelectUnmemoized({ daySelected, onDaySelect }) {
    const theme = useTheme();
    return (
      <Stack direction="column" gap={2}>
        <GradientTypography variant="h6" gradientColor="primary.vertical">
          Select Day
        </GradientTypography>
        <Stack direction="row" gap={4}>
          {DAYS_OF_WEEK.map((day) => {
            return (
              <Avatar
                onClick={() => {
                  return onDaySelect(day);
                }}
                key={day}
                sx={{
                  cursor: 'pointer',
                  fontSize: '18px',
                  background:
                    daySelected === day
                      ? theme.palette.primary.horizontal
                      : theme.palette.background.elevationSolid[8],
                  '&:hover': {
                    ...(daySelected === day
                      ? {}
                      : {
                          background:
                            theme.palette.background.elevationSolid[14],
                          color: theme.palette.primary.light,
                        }),
                  },
                  transition: '0.2s ease all',
                }}
              >
                {day[0].toUpperCase()}
              </Avatar>
            );
          })}
        </Stack>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.daySelected === nextProps.daySelected;
  },
);
