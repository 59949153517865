import ListItem, { ListItemProps } from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { memo } from '../../util/memo';

export type TitleCardProps = {
  title: string;
} & ListItemProps;

const TitleCardUnmemoized: React.FC<TitleCardProps> = ({
  title,
  onClick,
  ...props
}) => {
  return (
    <ListItem onClick={onClick} {...props}>
      <Typography>{title}</Typography>
    </ListItem>
  );
};

export const TitleCard = memo(TitleCardUnmemoized);
