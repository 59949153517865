import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';
import { FC } from 'react';
import { memo } from '../../util/memo';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { Bracket } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { Heat } from '../../../functions/src/types/firestore/Game/Tournament/Heat';
import { useDocSnapshot } from '../../hooks/firestore/useDocSnapshot';
import { useCollectionSnapshot } from '../../hooks/firestore/useCollectionSnapshot';
import { TournamentTitle } from './TournamentTitle';
import { RefereeProvider } from '../../contexts/RefereeContext';
import { TournamentTabs } from './TournamentTabs';
import { HeatsContext } from '../../contexts/docs/HeatsContext';
import { BracketContext } from '../../contexts/docs/BracketContext';
import Stack from '@mui/material/Stack';
// import { LivestreamBanner } from '../livestream/LivestreamBanner';
// import { SelectedVideoUrlProvider } from '../../contexts/SelectedVideoUrlContext';
// TODO: When moving to Next13, this component should be easy to turn into a page.

export type TournamentDetailsProps = {
  tournament: Tournament<Date>;
  tournamentPath: string;
  heats: Heat<Date>[];
  heatsPath: string;
  bracket?: Bracket<Date>;
  bracketPath: string;
};

const TournamentDetailsUnmemoized: FC<TournamentDetailsProps> = ({
  tournament: tournamentInitial,
  tournamentPath,
  heats: heatsInitial,
  heatsPath,
  bracket: bracketInitial,
  bracketPath,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tournament = useDocSnapshot(tournamentPath, tournamentInitial)!;
  const heats = useCollectionSnapshot(heatsPath, heatsInitial);
  const bracket = useDocSnapshot(bracketPath, bracketInitial);
  return (
    <TournamentContext.Provider value={tournament}>
      <Stack spacing={4}>
        <TournamentTitle />
        {/* <SelectedVideoUrlProvider> */}
        {/* <LivestreamBanner /> */}
        {/* </SelectedVideoUrlProvider> */}
        <RefereeProvider>
          <HeatsContext.Provider value={heats}>
            <BracketContext.Provider value={bracket}>
              <TournamentTabs />
            </BracketContext.Provider>
          </HeatsContext.Provider>
        </RefereeProvider>
      </Stack>
    </TournamentContext.Provider>
  );
};

export const TournamentDetails = memo(TournamentDetailsUnmemoized);
