import { FC, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';

export type DialogActionsLivestreamProps = {
  onCancel?: () => void;
};

const DialogActionsLivestreamUnmemoized: FC<DialogActionsLivestreamProps> = ({
  onCancel,
}) => {
  const buttons = useMemo(() => {
    return [
      {
        isAsync: false as const,
        onClick: () => {
          return onCancel?.();
        },
        sx: { flex: 1 },
        children: 'Close',
      },
    ];
  }, [onCancel]);

  return <DialogActionsStandard buttons={buttons} />;
};

export const DialogActionsLivestream = memo(DialogActionsLivestreamUnmemoized);
