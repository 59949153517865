import { useMemo } from 'react';
import { EventFlowBase } from './EventFlowBase';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { DayOfWeek } from 'src/contexts/organizer/create-event/constants';
import { GradientTypography } from '../../gradients/GradientTypography';
import { Fade, MenuItem, Select, Stack, Typography } from '@mui/material';
import { GradientRadioGroup } from '../prize-pools/GradientRadioGroup';
import { RepeatEvery, RepeatEveryUnit } from './recurring-event/RepeatEvery';
import { DaySelect } from './recurring-event/DaySelect';

export const EventFrequency = () => {
  const { eventCreationSettings, dispatchEventCreationSettings } =
    useCreateEventContext();

  const { isRecurring, recurrence } = eventCreationSettings;

  const { repeatDay, repeatEvery, repeatTimes } = recurrence;
  const options = useMemo(() => {
    const yesItems = [
      {
        key: 'frequency',
        component: (
          <RepeatEvery
            unitSelected={repeatEvery.timePeriod}
            numberSelected={repeatEvery.frequency}
            onNumberSelect={(count: number) => {
              return dispatchEventCreationSettings({
                type: 'SET_REPETITION_FREQUENCY',
                payload: count,
              });
            }}
            onUnitSelect={(unit: RepeatEveryUnit) => {
              return dispatchEventCreationSettings({
                type: 'SET_REPETITION_UNITS',
                payload: unit,
              });
            }}
          />
        ),
      },
      {
        key: 'selectDay',
        component: (
          <DaySelect
            daySelected={repeatDay}
            onDaySelect={(day: DayOfWeek) => {
              return dispatchEventCreationSettings({
                type: 'SET_REPETITION_DAY_OF_WEEK',
                payload: day,
              });
            }}
          />
        ),
      },
      {
        key: 'occurrences',
        component: (
          <Stack direction="column" width={'100%'}>
            <GradientTypography
              variant="subtitle1"
              gradientColor="primary.vertical"
            >
              Number of Occurrences
            </GradientTypography>
            <Select
              variant="filled"
              fullWidth
              value={repeatTimes}
              onChange={(e) => {
                return dispatchEventCreationSettings({
                  type: 'SET_REPETITION_COUNT',
                  payload: Number(e.target.value),
                });
              }}
            >
              {[1, 2, 3, 4].map((count) => {
                return (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        ),
      },
    ];
    return [
      {
        label: <Typography variant="subtitle1">Yes</Typography>,
        key: 'yes',
        contentOnSelect: (
          <Stack direction="column" gap={2} width={'100%'} pl={20}>
            {yesItems.map(({ key, component }, index) => {
              return (
                <Fade in={true} key={key} timeout={{ enter: 150 + 50 * index }}>
                  <div>{component}</div>
                </Fade>
              );
            })}
          </Stack>
        ),
      },
      { label: <Typography variant="subtitle1">No</Typography>, key: 'no' },
    ];
  }, [
    dispatchEventCreationSettings,
    repeatDay,
    repeatEvery.frequency,
    repeatEvery.timePeriod,
    repeatTimes,
  ]);
  return (
    <EventFlowBase
      title={'Is this a recurring event?'}
      subtitle={`Event cards for the dates selected will be added to your calendar.
      Individual prize pools will still have to be linked before
      publishing.`}
      Content={
        <GradientRadioGroup
          selectedIndex={isRecurring ? 0 : 1}
          onValueChange={(index) => {
            return dispatchEventCreationSettings({
              type: 'SET_IS_RECURRING',
              payload: !index,
            });
          }}
          items={options}
        />
      }
      continueButtonProps={{ isAsync: false }}
      //TODO: implement onClick when we implement recurring events
    />
  );
};
