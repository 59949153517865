import { Select, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { GradientTypography } from '../../../gradients/GradientTypography';

const REPEAT_EVERY_UNITS = ['weeks', 'months'] as const;

export type RepeatEveryUnit = typeof REPEAT_EVERY_UNITS[number];

type RepeatEveryProps = {
  numberSelected: number;
  unitSelected: RepeatEveryUnit;
  onNumberSelect: (x: number) => void;
  onUnitSelect: (unit: RepeatEveryUnit) => void;
};

export const RepeatEvery: FC<RepeatEveryProps> = memo(
  function RepeatEveryUnmemoized({
    unitSelected,
    numberSelected,
    onUnitSelect,
    onNumberSelect,
  }) {
    const numbers = useMemo(() => {
      const UNIT_MAP = {
        weeks: 4,
        months: 12,
      };
      return Array.from({ length: UNIT_MAP[String(unitSelected)] }, (_, i) => {
        return i + 1;
      });
    }, [unitSelected]);
    return (
      <Stack direction="column" width={'100%'}>
        <GradientTypography
          variant="subtitle1"
          gradientColor="primary.vertical"
        >
          Repeat Every
        </GradientTypography>
        <Stack direction="row" width={'100%'} gap={4} justifyContent="stretch">
          <Select
            variant="filled"
            fullWidth
            value={numberSelected}
            onChange={(e) => {
              return onNumberSelect(Number(e.target.value));
            }}
          >
            {numbers.map((number) => {
              return (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            variant="filled"
            value={unitSelected}
            fullWidth
            onChange={(e) => {
              return onUnitSelect(
                e.target.value.toLowerCase() as RepeatEveryUnit,
              );
            }}
            sx={{ textTransform: 'capitalize' }}
          >
            {REPEAT_EVERY_UNITS.map((unit) => {
              return (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.numberSelected === nextProps.numberSelected &&
      prevProps.unitSelected === nextProps.unitSelected
    );
  },
);
