import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopyRounded';
import { ClipboardShare } from './ClipboardShare';
import { memo } from '../util/memo';
import { useTheme, SxProps } from '@mui/material/styles';
import { GradientTypography } from './gradients/GradientTypography';
import { GradientIconButton } from './gradients/GradientIconButton';
import { ELLIPSIS_SX } from '../util/string';

export type CopyTextOutlinedProps = {
  title: string;
  text: string;
  textSx?: SxProps;
};

const CopyTextOutlinedUnmemoized = ({
  title,
  text,
  textSx = {},
}: CopyTextOutlinedProps) => {
  const theme = useTheme();
  return (
    <Stack alignItems="flex-start" spacing={1} width={'100%'}>
      <GradientTypography
        variant="subtitle1"
        sx={{ alignSelf: 'flex-start', textTransform: 'uppercase' }}
      >
        {title}
      </GradientTypography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 1,
          pl: 3,
          background: theme.palette.background.elevation[18],
          borderRadius: '10px',
          width: '100%',
        }}
      >
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            ...ELLIPSIS_SX,
            width: '100%',
            ...textSx,
          }}
        >
          {text}
        </Typography>
        <ClipboardShare value={text}>
          <GradientIconButton
            sx={{ height: '20px' }}
            IconComponent={ContentCopyIcon}
          />
        </ClipboardShare>
      </Stack>
    </Stack>
  );
};

export const CopyTextOutlined = memo(CopyTextOutlinedUnmemoized);
