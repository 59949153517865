import { memo } from '../../../util/memo';
import { useFindChannelId } from '../../../hooks/messaging/useFindChannelId';
import { useMemo } from 'react';
import { LivestreamConfigurationButton } from './LivestreamConfigurationButton';
import { useActiveTournamentChannel } from '../../../hooks/messaging/useActiveTournamentChannel';

export type TournamentLivestreamButtonProps = {
  tournamentId: string;
};

const TournamentLivestreamButtonUnmemoized = ({
  tournamentId,
}: TournamentLivestreamButtonProps) => {
  const { findGeneralChannelId } = useFindChannelId();
  const { openGeneralChannel } = useActiveTournamentChannel(tournamentId);
  const channelId = findGeneralChannelId(tournamentId);

  const livestreamConfigurationButton = useMemo(() => {
    return (
      !!channelId && (
        <LivestreamConfigurationButton
          channelId={channelId}
          onClose={openGeneralChannel}
        />
      )
    );
  }, [channelId, openGeneralChannel]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{livestreamConfigurationButton}</>;
};

export const TournamentLivestreamButton = memo(
  TournamentLivestreamButtonUnmemoized,
);
